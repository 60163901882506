import { CountryCode } from '../constants/countryCode';
import { Locale, LocaleFormatEntry } from '../constants/locale';

type CountryNamesByLocale = Record<CountryCode, LocaleFormatEntry>;

const COUNTRY_NAMES: CountryNamesByLocale = {
  [CountryCode.US]: {
    default: 'United States of America',
  },
  [CountryCode.CA]: {
    default: 'Canada',
  },
  [CountryCode.GB]: {
    default: 'United Kingdom',
  },
  [CountryCode.AU]: {
    default: 'Australia',
  },
  [CountryCode.FR]: { default: 'France' },
  [CountryCode.IE]: { default: 'Ireland' },
  [CountryCode.IT]: { default: 'Italy' },
  [CountryCode.NL]: { default: 'Netherlands' },
  [CountryCode.PT]: { default: 'Portugal' },
  [CountryCode.ES]: { default: 'Spain' },
  [CountryCode.SE]: { default: 'Sweden' },

  [CountryCode.AD]: { default: 'Andorra' },
  [CountryCode.AE]: { default: 'United Arab Emirates' },
  [CountryCode.AF]: { default: 'Afghanistan' },
  [CountryCode.AG]: { default: 'Antigua and Barbuda' },
  [CountryCode.AI]: { default: 'Anguilla' },
  [CountryCode.AL]: { default: 'Albania' },
  [CountryCode.AM]: { default: 'Armenia' },
  [CountryCode.AO]: { default: 'Angola' },
  [CountryCode.AP]: { default: 'Asia/Pacific Region' },
  [CountryCode.AQ]: { default: 'Antarctica' },
  [CountryCode.AR]: { default: 'Argentina' },
  [CountryCode.AS]: { default: 'American Samoa' },
  [CountryCode.AT]: { default: 'Austria' },
  [CountryCode.AW]: { default: 'Aruba' },
  [CountryCode.AX]: { default: 'Aland Islands' },
  [CountryCode.AZ]: { default: 'Azerbaijan' },
  [CountryCode.BA]: { default: 'Bosnia and Herzegovina' },
  [CountryCode.BB]: { default: 'Barbados' },
  [CountryCode.BD]: { default: 'Bangladesh' },
  [CountryCode.BE]: { default: 'Belgium' },
  [CountryCode.BF]: { default: 'Burkina Faso' },
  [CountryCode.BG]: { default: 'Bulgaria' },
  [CountryCode.BH]: { default: 'Bahrain' },
  [CountryCode.BI]: { default: 'Burundi' },
  [CountryCode.BJ]: { default: 'Benin' },
  [CountryCode.BL]: { default: 'Saint Barthelemy' },
  [CountryCode.BM]: { default: 'Bermuda' },
  [CountryCode.BN]: { default: 'Brunei Darussalam' },
  [CountryCode.BO]: { default: 'Bolivia' },
  [CountryCode.BQ]: { default: 'Bonaire, Saint Eustatius and Saba' },
  [CountryCode.BR]: { default: 'Brazil' },
  [CountryCode.BS]: { default: 'Bahamas' },
  [CountryCode.BT]: { default: 'Bhutan' },
  [CountryCode.BV]: { default: 'Bouvet Island' },
  [CountryCode.BW]: { default: 'Botswana' },
  [CountryCode.BY]: { default: 'Belarus' },
  [CountryCode.BZ]: { default: 'Belize' },
  [CountryCode.CC]: { default: 'Cocos (Keeling) Islands' },
  [CountryCode.CD]: { default: 'Congo, The Democratic Republic of the' },
  [CountryCode.CF]: { default: 'Central African Republic' },
  [CountryCode.CG]: { default: 'Congo' },
  [CountryCode.CH]: { default: 'Switzerland' },
  [CountryCode.CI]: { default: "Cote d'Ivoire" },
  [CountryCode.CK]: { default: 'Cook Islands' },
  [CountryCode.CL]: { default: 'Chile' },
  [CountryCode.CM]: { default: 'Cameroon' },
  [CountryCode.CN]: { default: 'China' },
  [CountryCode.CO]: { default: 'Colombia' },
  [CountryCode.CR]: { default: 'Costa Rica' },
  [CountryCode.CU]: { default: 'Cuba' },
  [CountryCode.CV]: { default: 'Cape Verde' },
  [CountryCode.CW]: { default: 'Curacao' },
  [CountryCode.CX]: { default: 'Christmas Island' },
  [CountryCode.CY]: { default: 'Cyprus' },
  [CountryCode.CZ]: { default: 'Czech Republic' },
  [CountryCode.DE]: { default: 'Germany' },
  [CountryCode.DJ]: { default: 'Djibouti' },
  [CountryCode.DK]: { default: 'Denmark' },
  [CountryCode.DM]: { default: 'Dominica' },
  [CountryCode.DO]: { default: 'Dominican Republic' },
  [CountryCode.DZ]: { default: 'Algeria' },
  [CountryCode.EC]: { default: 'Ecuador' },
  [CountryCode.EE]: { default: 'Estonia' },
  [CountryCode.EG]: { default: 'Egypt' },
  [CountryCode.EH]: { default: 'Western Sahara' },
  [CountryCode.ER]: { default: 'Eritrea' },
  [CountryCode.ET]: { default: 'Ethiopia' },
  [CountryCode.EU]: { default: 'Europe' },
  [CountryCode.FI]: { default: 'Finland' },
  [CountryCode.FJ]: { default: 'Fiji' },
  [CountryCode.FK]: { default: 'Falkland Islands (Malvinas)' },
  [CountryCode.FM]: { default: 'Micronesia, Federated States of' },
  [CountryCode.FO]: { default: 'Faroe Islands' },
  [CountryCode.GA]: { default: 'Gabon' },
  [CountryCode.GD]: { default: 'Grenada' },
  [CountryCode.GE]: { default: 'Georgia' },
  [CountryCode.GF]: { default: 'French Guiana' },
  [CountryCode.GG]: { default: 'Guernsey' },
  [CountryCode.GH]: { default: 'Ghana' },
  [CountryCode.GI]: { default: 'Gibraltar' },
  [CountryCode.GL]: { default: 'Greenland' },
  [CountryCode.GM]: { default: 'Gambia' },
  [CountryCode.GN]: { default: 'Guinea' },
  [CountryCode.GP]: { default: 'Guadeloupe' },
  [CountryCode.GQ]: { default: 'Equatorial Guinea' },
  [CountryCode.GR]: { default: 'Greece' },
  [CountryCode.GS]: { default: 'South Georgia and the South Sandwich Islands' },
  [CountryCode.GT]: { default: 'Guatemala' },
  [CountryCode.GU]: { default: 'Guam' },
  [CountryCode.GW]: { default: 'Guinea-Bissau' },
  [CountryCode.GY]: { default: 'Guyana' },
  [CountryCode.HK]: { default: 'Hong Kong' },
  [CountryCode.HM]: { default: 'Heard Island and McDonald Islands' },
  [CountryCode.HN]: { default: 'Honduras' },
  [CountryCode.HR]: { default: 'Croatia' },
  [CountryCode.HT]: { default: 'Haiti' },
  [CountryCode.HU]: { default: 'Hungary' },
  [CountryCode.ID]: { default: 'Indonesia' },
  [CountryCode.IL]: { default: 'Israel' },
  [CountryCode.IM]: { default: 'Isle of Man' },
  [CountryCode.IN]: { default: 'India' },
  [CountryCode.IO]: { default: 'British Indian Ocean Territory' },
  [CountryCode.IQ]: { default: 'Iraq' },
  [CountryCode.IR]: { default: 'Iran, Islamic Republic of' },
  [CountryCode.IS]: { default: 'Iceland' },
  [CountryCode.JE]: { default: 'Jersey' },
  [CountryCode.JM]: { default: 'Jamaica' },
  [CountryCode.JO]: { default: 'Jordan' },
  [CountryCode.JP]: { default: 'Japan' },
  [CountryCode.KE]: { default: 'Kenya' },
  [CountryCode.KG]: { default: 'Kyrgyzstan' },
  [CountryCode.KH]: { default: 'Cambodia' },
  [CountryCode.KI]: { default: 'Kiribati' },
  [CountryCode.KM]: { default: 'Comoros' },
  [CountryCode.KN]: { default: 'Saint Kitts and Nevis' },
  [CountryCode.KP]: { default: "Korea, Democratic People's Republic of" },
  [CountryCode.KR]: { default: 'Korea, Republic of' },
  [CountryCode.KW]: { default: 'Kuwait' },
  [CountryCode.KY]: { default: 'Cayman Islands' },
  [CountryCode.KZ]: { default: 'Kazakhstan' },
  [CountryCode.LA]: { default: "Lao People's Democratic Republic" },
  [CountryCode.LB]: { default: 'Lebanon' },
  [CountryCode.LC]: { default: 'Saint Lucia' },
  [CountryCode.LI]: { default: 'Liechtenstein' },
  [CountryCode.LK]: { default: 'Sri Lanka' },
  [CountryCode.LR]: { default: 'Liberia' },
  [CountryCode.LS]: { default: 'Lesotho' },
  [CountryCode.LT]: { default: 'Lithuania' },
  [CountryCode.LU]: { default: 'Luxembourg' },
  [CountryCode.LV]: { default: 'Latvia' },
  [CountryCode.LY]: { default: 'Libyan Arab Jamahiriya' },
  [CountryCode.MA]: { default: 'Morocco' },
  [CountryCode.MC]: { default: 'Monaco' },
  [CountryCode.MD]: { default: 'Moldova, Republic of' },
  [CountryCode.ME]: { default: 'Montenegro' },
  [CountryCode.MF]: { default: 'Saint Martin' },
  [CountryCode.MG]: { default: 'Madagascar' },
  [CountryCode.MH]: { default: 'Marshall Islands' },
  [CountryCode.MK]: { default: 'Macedonia' },
  [CountryCode.ML]: { default: 'Mali' },
  [CountryCode.MM]: { default: 'Myanmar' },
  [CountryCode.MN]: { default: 'Mongolia' },
  [CountryCode.MO]: { default: 'Macao' },
  [CountryCode.MP]: { default: 'Northern Mariana Islands' },
  [CountryCode.MQ]: { default: 'Martinique' },
  [CountryCode.MR]: { default: 'Mauritania' },
  [CountryCode.MS]: { default: 'Montserrat' },
  [CountryCode.MT]: { default: 'Malta' },
  [CountryCode.MU]: { default: 'Mauritius' },
  [CountryCode.MV]: { default: 'Maldives' },
  [CountryCode.MW]: { default: 'Malawi' },
  [CountryCode.MX]: { default: 'Mexico' },
  [CountryCode.MY]: { default: 'Malaysia' },
  [CountryCode.MZ]: { default: 'Mozambique' },
  [CountryCode.NA]: { default: 'Namibia' },
  [CountryCode.NC]: { default: 'New Caledonia' },
  [CountryCode.NE]: { default: 'Niger' },
  [CountryCode.NF]: { default: 'Norfolk Island' },
  [CountryCode.NG]: { default: 'Nigeria' },
  [CountryCode.NI]: { default: 'Nicaragua' },
  [CountryCode.NO]: { default: 'Norway' },
  [CountryCode.NP]: { default: 'Nepal' },
  [CountryCode.NR]: { default: 'Nauru' },
  [CountryCode.NU]: { default: 'Niue' },
  [CountryCode.NZ]: { default: 'New Zealand' },
  [CountryCode.OM]: { default: 'Oman' },
  [CountryCode.PA]: { default: 'Panama' },
  [CountryCode.PE]: { default: 'Peru' },
  [CountryCode.PF]: { default: 'French Polynesia' },
  [CountryCode.PG]: { default: 'Papua New Guinea' },
  [CountryCode.PH]: { default: 'Philippines' },
  [CountryCode.PK]: { default: 'Pakistan' },
  [CountryCode.PL]: { default: 'Poland' },
  [CountryCode.PM]: { default: 'Saint Pierre and Miquelon' },
  [CountryCode.PN]: { default: 'Pitcairn' },
  [CountryCode.PR]: { default: 'Puerto Rico' },
  [CountryCode.PS]: { default: 'Palestinian Territory' },
  [CountryCode.PW]: { default: 'Palau' },
  [CountryCode.PY]: { default: 'Paraguay' },
  [CountryCode.QA]: { default: 'Qatar' },
  [CountryCode.RE]: { default: 'Reunion' },
  [CountryCode.RO]: { default: 'Romania' },
  [CountryCode.RS]: { default: 'Serbia' },
  [CountryCode.RU]: { default: 'Russian Federation' },
  [CountryCode.RW]: { default: 'Rwanda' },
  [CountryCode.SA]: { default: 'Saudi Arabia' },
  [CountryCode.SB]: { default: 'Solomon Islands' },
  [CountryCode.SC]: { default: 'Seychelles' },
  [CountryCode.SD]: { default: 'Sudan' },
  [CountryCode.SG]: { default: 'Singapore' },
  [CountryCode.SH]: { default: 'Saint Helena' },
  [CountryCode.SI]: { default: 'Slovenia' },
  [CountryCode.SJ]: { default: 'Svalbard and Jan Mayen' },
  [CountryCode.SK]: { default: 'Slovakia' },
  [CountryCode.SL]: { default: 'Sierra Leone' },
  [CountryCode.SM]: { default: 'San Marino' },
  [CountryCode.SN]: { default: 'Senegal' },
  [CountryCode.SO]: { default: 'Somalia' },
  [CountryCode.SR]: { default: 'Suriname' },
  [CountryCode.SS]: { default: 'South Sudan' },
  [CountryCode.ST]: { default: 'Sao Tome and Principe' },
  [CountryCode.SV]: { default: 'El Salvador' },
  [CountryCode.SX]: { default: 'Sint Maarten' },
  [CountryCode.SY]: { default: 'Syrian Arab Republic' },
  [CountryCode.SZ]: { default: 'Swaziland' },
  [CountryCode.TC]: { default: 'Turks and Caicos Islands' },
  [CountryCode.TD]: { default: 'Chad' },
  [CountryCode.TF]: { default: 'French Southern Territories' },
  [CountryCode.TG]: { default: 'Togo' },
  [CountryCode.TH]: { default: 'Thailand' },
  [CountryCode.TJ]: { default: 'Tajikistan' },
  [CountryCode.TK]: { default: 'Tokelau' },
  [CountryCode.TL]: { default: 'Timor-Leste' },
  [CountryCode.TM]: { default: 'Turkmenistan' },
  [CountryCode.TN]: { default: 'Tunisia' },
  [CountryCode.TO]: { default: 'Tonga' },
  [CountryCode.TR]: { default: 'Turkey' },
  [CountryCode.TT]: { default: 'Trinidad and Tobago' },
  [CountryCode.TV]: { default: 'Tuvalu' },
  [CountryCode.TW]: { default: 'Taiwan' },
  [CountryCode.TZ]: { default: 'Tanzania, United Republic of' },
  [CountryCode.UA]: { default: 'Ukraine' },
  [CountryCode.UG]: { default: 'Uganda' },
  [CountryCode.UM]: { default: 'United States Minor Outlying Islands' },
  [CountryCode.UY]: { default: 'Uruguay' },
  [CountryCode.UZ]: { default: 'Uzbekistan' },
  [CountryCode.VA]: { default: 'Holy See (Vatican City State)' },
  [CountryCode.VC]: { default: 'Saint Vincent and the Grenadines' },
  [CountryCode.VE]: { default: 'Venezuela' },
  [CountryCode.VG]: { default: 'Virgin Islands, British' },
  [CountryCode.VI]: { default: 'Virgin Islands, U.S.' },
  [CountryCode.VN]: { default: 'Vietnam' },
  [CountryCode.VU]: { default: 'Vanuatu' },
  [CountryCode.WF]: { default: 'Wallis and Futuna' },
  [CountryCode.WS]: { default: 'Samoa' },
  [CountryCode.YE]: { default: 'Yemen' },
  [CountryCode.YT]: { default: 'Mayotte' },
  [CountryCode.ZA]: { default: 'South Africa' },
  [CountryCode.ZM]: { default: 'Zambia' },
  [CountryCode.ZW]: { default: 'Zimbabwe' },
};

export function countryNamesMap(locale: Locale = Locale.enUS) {
  return buildCountryNamesMap(COUNTRY_NAMES, locale);
}

export function getCountryName(country: CountryCode, locale: Locale = Locale.enUS) {
  const countryName = COUNTRY_NAMES[country];

  // If there is not a locale override for the given country, use the country default
  return countryName[locale] || countryName.default;
}

function buildCountryNamesMap(data: CountryNamesByLocale, locale: Locale) {
  return Object.entries(data).reduce((acc, [countryCode, currentCountry]) => {
    acc[countryCode] = currentCountry[locale] || currentCountry.default;
    return acc;
  }, {} as Record<string, string>);
}
